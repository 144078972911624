import { zodResolver } from '@hookform/resolvers/zod';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useQueryClient } from '@tanstack/react-query';
import {
  addMonths,
  addQuarters,
  addWeeks,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  format,
  parse,
} from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useGetDropdownWithSearchAPI } from 'src/apis/dropdownAPI/get';
import { useGetFilterAPI } from 'src/apis/filterAPI';
import { useGetShortNameWithoutUrl } from 'src/apis/indexAPI';
import {
  GROUP_BY_PROJECT_KEY,
  GROUP_BY_RESOURCE_KEY,
  GROUP_BY_RESOURCE_TOTAL_KEY,
  useGetMigrationStatus,
  useGetResourceViewOptions,
} from 'src/apis/resourcePlannerAPI';
import { ISavedView } from 'src/apis/resourcePlannerAPI/savedViewAPI';
import { IFilterItemProperties } from 'src/apis/types/filterListAPI';
import { useGetFirstName, useGetUserId } from 'src/apis/usersAPI/meAPI';
import { FilterLayout } from 'src/components/layout/FilterLayout';
import { ToastifyAlert } from 'src/components/mui-components';
import { Spinner } from 'src/components/ui-components';
import ResponseHandler from 'src/components/utils/ResponseHandler';
import { IMenuItem } from 'src/reducers/FilterReducer/FilterReducer';
import { GetRPSavedViewsKey } from 'src/screens/ResourcePlanner/localStorageKeys';
import { TSavedViewAction, TViewOptions } from 'src/screens/ResourcePlanner/types/resourcePlanner';
import { useFilterStore } from 'src/stores/FilterStore';
import { useGetCurrentPageIdentifier } from 'src/stores/PageStore';
import { safeParseJson } from 'src/utils/object';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';
import { InitialSetup } from './components/InitialSetup';
import { PeriodPicker } from './components/PeriodPicker';
import { ResourceTableGroupedByEmployee } from './components/ResourceTableGroupedByEmployee';
import { ResourceTableGroupedByProject } from './components/ResourceTableGroupedByProject';
import { ViewFormDialog, ViewTextDialog } from './components/SavedViewDialog';
import { INITIAL_SAVED_VIEW, SNACKBAR_CONFIG } from './constants';
import { sortViews } from './helper/sortViews';
import { useSignalRConnection } from './hooks';
import {
  RPSelectedFilterListStateKey,
  RPViewOptionsStateKey,
  RPinvalidateOldQueryOnViewOptionChange,
} from './localStorageKeys';
import {
  IOptionsChangeParameters,
  ISavedViewForm,
  SnackbarMode,
  TFormDialogMode,
  TSnackbarKeys,
  TTextDialogMode,
  TViewOptionKey,
  ViewType,
} from './types/resourcePlanner';

export const ResourcePlanner = () => {
  const { t } = useTranslation('savedView');
  const qc = useQueryClient();

  localStorage.setItem('resource-planner-filter-is-open', 'true');
  const { shortNameWithoutUrl } = useGetShortNameWithoutUrl();
  const localStorageSavedViews =
    localStorage.getItem(GetRPSavedViewsKey(shortNameWithoutUrl)) ?? '';
  const [savedViews, setSavedViews] = useState<ISavedView[]>([]);

  const pageIdentifier = useGetCurrentPageIdentifier();
  const { filterList, isError, isLoading } = useGetFilterAPI(pageIdentifier);

  const [rpLoading, setRpLoading] = useState(true);

  // Patch for no saved views
  const flattenedFilterItemsList = filterList.reduce(
    (a, { filterItems }) => [...a, ...(filterItems ?? [])],
    [] as IFilterItemProperties[],
  );
  const employeeFilter = flattenedFilterItemsList.find(({ name }) => name === 'Employee');
  const { firstName } = useGetFirstName();
  const { dropdownList } = useGetDropdownWithSearchAPI({
    key: `filterInput${employeeFilter?.id}`,
    MSWKey: `FILTER_INPUT_${employeeFilter?.id}`,
    path: `/${employeeFilter?.contentUrl}?pageIdentifier=${pageIdentifier}`,
    searchText: firstName,
    enabled: !!employeeFilter?.contentUrl && !savedViews.length,
  });
  const { userId } = useGetUserId();
  const currentUserOption = dropdownList?.find((item) => item.value === String(userId));
  // Patch for no saved views

  const sortedViews = useMemo((): ISavedView[] => sortViews(savedViews), [savedViews]);
  const [activeView, setActiveView] = useState<string>();

  const savedViewOptions = sortedViews?.find((v) => v.id === activeView)?.viewOptions;
  const [changedViewOptions, setChangedViewOptions] = useState<TViewOptions | undefined>(
    savedViewOptions ?? undefined,
  );

  const initialFilter = useMemo(() => {
    if (!currentUserOption || !employeeFilter) {
      return undefined;
    }

    return {
      Employee: {
        label: employeeFilter.name,
        values: [
          {
            label: currentUserOption.label,
            value: currentUserOption.value,
          },
        ],
        contentUrl: employeeFilter.contentUrl,
        childFilters: employeeFilter.childFilters,
        parentFilters: employeeFilter.parentFilters,
        isInclude: true,
      },
    };
  }, [currentUserOption, employeeFilter]);

  const updateSavedViews = useCallback(
    (sv: ISavedView[]) => {
      setSavedViews(() => {
        localStorage.setItem(GetRPSavedViewsKey(shortNameWithoutUrl), JSON.stringify(sv));
        return sv;
      });
    },
    [shortNameWithoutUrl],
  );

  useSignalRConnection();

  const { fields: initialFields } = useGetResourceViewOptions();
  const { filterQueryObj } = useFilterStore();
  const {
    isResourceMigrated,
    isProjectMigrated,
    isResourcePlanMigrated,
    isError: isMigratedError,
    isLoading: isMigratedLoading,
  } = useGetMigrationStatus();

  // #region Date range
  const getEndDateForViewOptions = useCallback(() => {
    const period = sortedViews.find((v) => v.id === activeView)?.period;

    switch (period) {
      case 'this_week':
        return endOfWeek(new Date(), { weekStartsOn: 1 });

      case 'this_and_next_week':
        return endOfWeek(addWeeks(new Date(), 1), { weekStartsOn: 1 });

      case 'this_month':
        return endOfMonth(new Date());

      case 'this_and_next_month':
        return endOfMonth(addMonths(new Date(), 1));

      case 'this_quarter':
        return endOfQuarter(new Date());

      case 'this_and_next_quarter':
        return endOfQuarter(addQuarters(new Date(), 1));

      case 'this_year':
        return endOfYear(new Date());

      case 'this_and_next_three_weeks':
      default:
        return endOfWeek(addWeeks(new Date(), 3), { weekStartsOn: 1 });
    }
  }, [activeView, sortedViews]);

  const getStartDateForViewOptions = () => new Date();

  const [initialDateEnd, setInitialDateEnd] = useState(getEndDateForViewOptions());
  const [initialDateStart, setInitialDateStart] = useState(getStartDateForViewOptions());
  // #endregion Date range

  const fields = useMemo(
    () =>
      initialFields.map((field) => ({
        ...field,
        value: changedViewOptions?.[field.name as TViewOptionKey] ?? field.value,
        options: field.options.map((option) => ({
          ...option,
          // label: tRP(`FieldOption${toPascal(option.value)}` as TFieldOptionKey),
        })),
      })),
    [changedViewOptions, initialFields],
  );

  const groupedBy = useMemo(() => {
    const groupedByViewOption = fields.find(({ name }) => name === 'grouped-by');
    return groupedByViewOption?.value as ViewType;
  }, [fields]);

  const selectedViewOptions = useMemo(
    () =>
      fields.reduce<TViewOptions>(
        (acc, { name, value }) => {
          if (value) {
            acc[name as keyof TViewOptions] = value;
          }
          return acc;
        },
        {
          'period-starts-at': format(initialDateStart, 'yyyy-MM-dd'),
          'period-ends-at': format(initialDateEnd, 'yyyy-MM-dd'),
        },
      ),
    [fields, initialDateEnd, initialDateStart],
  );

  const onViewOptionsChange = (items: IOptionsChangeParameters[]) => {
    const options = items.map((item) => {
      if (item.name === 'period-starts-at') {
        setInitialDateStart(parse(item.value, 'yyyy-MM-dd', new Date()));
      }

      if (item.name === 'period-ends-at') {
        setInitialDateEnd(parse(item.value, 'yyyy-MM-dd', new Date()));
      }
      return { [item.name]: item.value };
    });
    const optionsToObject = Object.assign({}, ...options);
    const invalidateOldQueries = localStorage.getItem(RPinvalidateOldQueryOnViewOptionChange);
    if (invalidateOldQueries === 'true') {
      // remove old queries of they are no longer in sync with expand / collapse state
      qc.removeQueries([GROUP_BY_RESOURCE_KEY, changedViewOptions || {}, filterQueryObj || {}]);
      qc.removeQueries([GROUP_BY_PROJECT_KEY, changedViewOptions || {}, filterQueryObj || {}]);
      qc.removeQueries([
        GROUP_BY_RESOURCE_TOTAL_KEY,
        changedViewOptions || {},
        filterQueryObj || {},
      ]);
      localStorage.setItem(RPinvalidateOldQueryOnViewOptionChange, 'false');
    }

    setChangedViewOptions({
      ...changedViewOptions,
      ...optionsToObject,
    });
  };

  const [showFormDialog, setShowFormDialog] = useState<TFormDialogMode | ''>('');
  const [showTextDialog, setShowTextDialog] = useState<TTextDialogMode | ''>('');

  const displayToast = (type: SnackbarMode) => {
    const config = SNACKBAR_CONFIG.find((c) => c.type === type);
    const { title, description } = config ?? {};

    qc.invalidateQueries(['INTERNAL_MESSAGE']);
    toast.success(
      <ToastifyAlert
        title={t(title as TSnackbarKeys)}
        description={t(description as TSnackbarKeys)}
      />,
      {
        autoClose: 5000,
        closeButton: false,
      },
    );
  };

  // form
  const formSchema = z.object({
    title: z.string().min(1, { message: 'Please enter title' }),
    period: z.string().min(1, { message: 'Please select period' }),
    isDefault: z.boolean(),
  });

  const form = useForm({
    defaultValues: {
      title: '',
      period: '',
      isDefault: false,
    } as ISavedViewForm,
    resolver: zodResolver(formSchema),
  });

  const menuItems: IMenuItem[] = [
    {
      id: 'edit',
      label: t('UpdateViewMenuText'),
      icon: <EditIcon />,
      action: () => {
        const viewIndex = sortedViews.find((v) => v.id === activeView);
        if (viewIndex) {
          form.setValue('title', viewIndex.title, { shouldDirty: true, shouldValidate: true });
          form.setValue('period', viewIndex.period, { shouldDirty: true, shouldValidate: true });
          form.setValue('isDefault', viewIndex.isDefault);
          setShowFormDialog('edit');
        }
      },
    },
    {
      id: 'duplicate',
      label: t('DuplicateViewMenuText'),
      icon: <ContentCopyRoundedIcon />,
      action: () => {
        const viewIndex = sortedViews.find((v) => v.id === activeView);
        if (viewIndex) {
          form.setValue('title', viewIndex.title, { shouldDirty: true, shouldValidate: true });
          form.setValue('period', viewIndex.period, { shouldDirty: true, shouldValidate: true });
          form.setValue('isDefault', viewIndex.isDefault);
          setShowFormDialog('duplicate');
        }
      },
    },
    {
      id: 'delete',
      label: t('DeleteViewMenuText'),
      icon: <DeleteIcon />,
      action: () => {
        setShowTextDialog('delete');
      },
    },
  ];

  const removePeriodFromViewOptions = (viewOptions?: TViewOptions) => {
    if (!viewOptions) {
      return {};
    }
    const {
      'period-ends-at': periodEndsAt,
      'period-starts-at': periodStartsAt,
      ...rest
    } = viewOptions;
    return rest;
  };

  const selectedViewOptionsWithoutPeriod = useMemo(
    () => removePeriodFromViewOptions(selectedViewOptions),
    [selectedViewOptions],
  );

  const changedViewOptionsWithoutPeriod = useMemo(
    () => removePeriodFromViewOptions(changedViewOptions),
    [changedViewOptions],
  );

  const updateActiveView = (viewId?: string) => {
    setActiveView(() => {
      setRpLoading(true);
      return viewId;
    });
  };

  const onSubmit = (data: ISavedViewForm) => {
    // set default to false for all views
    const viewData: ISavedView[] = savedViews.map((view) => ({
      ...view,
      isDefault: data.isDefault ? false : view.isDefault,
    }));

    const filters = localStorage.getItem(RPSelectedFilterListStateKey);
    const newID = uuidv4();
    let updatedData: ISavedView[];

    switch (showFormDialog) {
      case 'save':
        if (!filters) {
          return;
        }

        updatedData = [
          ...viewData,
          {
            id: newID,
            title: data.title,
            period: data.period,
            isDefault: data.isDefault,
            createdDate: new Date(),
            filters: safeParseJson(filters),
            viewOptions: selectedViewOptionsWithoutPeriod,
          },
        ];
        displayToast('save');
        updateActiveView(newID);
        break;

      case 'edit':
        updatedData = viewData.map((view) => {
          if (view.id === activeView) {
            return {
              ...view,
              title: data.title,
              period: data.period,
              isDefault: data.isDefault,
            };
          }
          return view;
        });
        displayToast('edit');
        break;

      default:
        updatedData = [
          ...viewData,
          {
            id: newID,
            title: data.title,
            period: data.period,
            isDefault: data.isDefault,
            createdDate: new Date(),
            filters: filters ? safeParseJson(filters) : {},
            viewOptions: selectedViewOptionsWithoutPeriod,
          },
        ];
        displayToast('duplicate');
        updateActiveView(newID);
        break;
    }

    updateSavedViews(updatedData);
    form.reset();
  };

  const onDelete = () => {
    setShowTextDialog('');
    const updatedData = savedViews.filter((view) => view.id !== activeView);
    updateSavedViews(updatedData);
    updateActiveView(sortViews(updatedData)[0]?.id ?? '');
    displayToast('delete');
  };

  const onChangesUpdate = () => {
    const updatedSaveView = savedViews.map((v) => {
      const view = { ...v };
      if (view.id === activeView) {
        view.viewOptions = selectedViewOptionsWithoutPeriod;
        view.filters = safeParseJson(localStorage.getItem(RPSelectedFilterListStateKey) ?? '');
      }
      return view;
    });

    updateSavedViews(updatedSaveView);
    setShowTextDialog('');
    displayToast('changes');
  };

  const handleViewAction = (action: TSavedViewAction) => {
    if (action === 'saveAs') {
      form.reset();
      setShowFormDialog('save');
      return;
    }
    onChangesUpdate();
  };

  useEffect(() => {
    const localStorageViews = safeParseJson(localStorageSavedViews);
    if (!localStorageViews?.length) {
      updateSavedViews([
        {
          ...INITIAL_SAVED_VIEW,
          id: uuidv4(),
          title: t('InitialViewTitle'),
          filters: initialFilter,
        },
      ]);
      return;
    }
    setSavedViews(localStorageViews);
  }, [initialFilter, localStorageSavedViews, shortNameWithoutUrl, t, updateSavedViews]);

  useEffect(() => {
    if (activeView || !sortedViews?.length) {
      return;
    }
    updateActiveView(sortedViews[0].id);
  }, [activeView, sortedViews]);

  useEffect(() => {
    if (!savedViewOptions) {
      return;
    }
    setChangedViewOptions(savedViewOptions);
  }, [savedViewOptions]);

  useEffect(() => {
    if (!changedViewOptions) {
      return;
    }
    localStorage.setItem(RPViewOptionsStateKey, JSON.stringify(changedViewOptions));
    setRpLoading(false);
  }, [changedViewOptions]);

  useEffect(() => {
    setShowFormDialog('');
    setShowTextDialog('');
    setInitialDateEnd(getEndDateForViewOptions());
  }, [getEndDateForViewOptions]);

  return (
    <ResponseHandler
      isLoading={isLoading || isMigratedLoading}
      isError={isError || isMigratedError}
    >
      {isResourceMigrated && isProjectMigrated && isResourcePlanMigrated ? (
        <ResponseHandler isLoading={isLoading} isEmpty={filterList.length <= 0} isError={isError}>
          <FilterLayout
            filterList={filterList}
            selectedFilterList={sortedViews?.[0]?.filters}
            viewOptionsChange={onViewOptionsChange}
            viewOptionsFields={fields}
            viewOptionsPeriodPicker={
              <PeriodPicker
                initialDateStart={initialDateStart}
                initialDateEnd={initialDateEnd}
                onChange={onViewOptionsChange}
              />
            }
            localStorageNamePrefix="resource-planner"
            allowSavedViews
            savedViewsList={sortedViews}
            activeView={activeView}
            menuItems={menuItems}
            handleViewAction={handleViewAction}
            setActiveView={updateActiveView}
            changedViewOptions={changedViewOptionsWithoutPeriod}
          >
            <>
              {!rpLoading ? (
                <>
                  {groupedBy === 'group-by-resource' && (
                    <ResourceTableGroupedByEmployee
                      rpLoading={rpLoading}
                      selectedViewOptions={selectedViewOptions}
                    />
                  )}
                  {groupedBy === 'group-by-work-item' && (
                    <ResourceTableGroupedByProject
                      rpLoading={rpLoading}
                      selectedViewOptions={selectedViewOptions}
                    />
                  )}
                </>
              ) : (
                <Spinner />
              )}

              {/* For form dialog */}
              {showFormDialog && (
                <ViewFormDialog
                  showFormDialog={showFormDialog}
                  setShowFormDialog={setShowFormDialog}
                  onSubmit={onSubmit}
                  form={form}
                />
              )}

              {/* For text alert dialog */}
              {showTextDialog && (
                <ViewTextDialog
                  showTextDialog={showTextDialog}
                  setShowTextDialog={setShowTextDialog}
                  onDelete={onDelete}
                />
              )}
            </>
          </FilterLayout>
        </ResponseHandler>
      ) : (
        <InitialSetup />
      )}
    </ResponseHandler>
  );
};
